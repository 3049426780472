import { BodyRegular, H3 } from "../../ui"
import { StatsContainer, Stat } from "./styled"

export const Stats = () => {
  return (
    <StatsContainer>
      <Stat>
        <H3>70%</H3>
        <BodyRegular>
          of clients report their depression improved within 12 weeks
        </BodyRegular>
      </Stat>
      <Stat>
        <H3>93%</H3>
        <BodyRegular>
          of medication plan clients have their first appointment within 14 days
        </BodyRegular>
      </Stat>
      <Stat>
        <H3>68%</H3>
        <BodyRegular>
          of clients have their first therapy appointment within a week
        </BodyRegular>
      </Stat>
    </StatsContainer>
  )
}
